import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Window from '../../components/Window';
import userContext from '../../contexts/userContext';
import { RegistrationEvent, meetingCreate } from '../../services/meeting';

const Home: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [descriptionShow, setDescriptionShow] = useState<boolean>(false);

  const { setUserInfo } = useContext(userContext);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const onFinish = async (values: RegistrationEvent) => {
    const { name, title, description } = values;

    try {
      setLoading(true);

      const { meetingId, userId } = await meetingCreate(
        name,
        title,
        description
      );

      setUserInfo(meetingId, {
        userId,
        userName: name,
        isOwner: true,
      });

      setLoading(false);

      navigate(`/${meetingId}`);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Window header="Создать Ваше первое мероприятие!">
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Имя:"
          rules={[{ required: true, message: 'Пожалуйста, введите Имя' }]}
        >
          <Input placeholder="Винни-Пух" />
        </Form.Item>
        <Form.Item
          name="title"
          label="Название мероприятия:"
          rules={[{ required: true, message: 'Пожалуйста, введите Название' }]}
        >
          <Input placeholder="Поход за медом" />
        </Form.Item>
        {!descriptionShow && (
          <div style={{ margin: '24px 0' }}>
            <Typography.Link
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDescriptionShow(!descriptionShow);
              }}
            >
              Добавить описание
            </Typography.Link>
          </div>
        )}
        {descriptionShow && (
          <Form.Item name="description" label="Описание:">
            <Input.TextArea
              rows={3}
              placeholder="Время пришло в гости отправиться, - Ждет меня старинный друг."
            />
          </Form.Item>
        )}
        <div className="space-between">
          <Tooltip placement="left" title="Очистить форму">
            <Button htmlType="button" onClick={() => form.resetFields()}>
              <CloseOutlined />
            </Button>
          </Tooltip>
          <Button type="primary" htmlType="submit" loading={loading}>
            Создать
          </Button>
        </div>
      </Form>
    </Window>
  );
};

export default Home;
