import { Radio } from 'antd';
import moment from 'moment';
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';

import { Option, Voter } from '../../services/poll';
import style from './FieldVote.module.sass';

interface EProps extends HTMLAttributes<string> {
  data?: Option;
  event?: (data: Option) => Promise<void>;
  active?: boolean;
  userVoice?: boolean;
  counterVoice?: number;
  finished?: boolean;
}
const FieldVote: React.FC<EProps> = (props) => {
  const { data, event, userVoice, counterVoice = 0, finished } = props;

  const [votingScale, setVotingScale] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      if (counterVoice > 0 && data?.voters) {
        setVotingScale(data.voters.length);
      }
    } catch (err) {}
  }, [counterVoice, data?.voters]);

  useEffect(() => {
    fetchData().then(() => {});
  }, [fetchData]);

  return (
    <>
      {!!data && !!data.optionId && (
        <div
          className={style.filedVote}
          onClick={() => {
            try {
              if (!userVoice && !finished && event) {
                event(data).then();
              }
            } catch (err) {}
          }}
        >
          <div className={style.filedVoteData}>
            <div className={style.filedVoteDataBand}>
              <div
                className={style.filedVoteDataBandScale}
                style={{
                  width: (data.voters.length * 100) / counterVoice + '%',
                }}
              >
                {votingScale > 0 ? <span>{votingScale}</span> : ''}
              </div>
            </div>
            {!userVoice && !finished ? <Radio value={data.optionId} /> : ''}
          </div>
          <div className={style.filedVoteInfo}>
            <span> {moment(data.dateTime).format('DD.MM.YYYY HH:mm')}</span>
            {data.voters.length > 0 && (
              <span>
                (
                {data.voters.map((el: Voter) => (
                  <span className={style.filedVoteInfoName} key={el.id}>
                    {el.name}
                  </span>
                ))}
                )
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FieldVote;
