import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Steps,
  TimePicker,
  Tooltip,
  Typography,
} from 'antd';
import moment, { Moment } from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import FieldVote from '../../components/FieldVote';
import GuestModal from '../../components/GuestModal';
import Header from '../../components/Header';
import Page from '../../components/Page';
import styles from '../../components/Page/Page.module.sass';
import userContext from '../../contexts/userContext';
import {
  MeetingView,
  RegistrationEvent,
  meetingAttendeeCreate,
  meetingFinalDateUpdate,
  meetingGet,
  meetingUpdate,
} from '../../services/meeting';
import {
  Option,
  Voter,
  pollGet,
  pollRemove,
  pollVoteAdd,
} from '../../services/poll';

const Poll: React.FC = () => {
  const [meetingData, setMeetingData] = useState<MeetingView>();

  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalSaveVote, setModalSaveVote] = useState<boolean>(false);
  const [modalSaveVoteIndependently, setModalSaveVoteIndependently] =
    useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalForm] = Form.useForm();
  const [modalFormVotes] = Form.useForm();

  const navigate = useNavigate();

  const [pollId, setPollId] = useState('');

  const { meetingId = '' } = useParams();
  const { getUserInfo, setUserInfo } = useContext(userContext);

  const [listVotes, setListVotes] = useState<Array<Option>>([]);
  const [selectedVotes, setSelectedVotes] = useState<Date>();

  const [voteActive, setVoteActive] = useState(Object);

  const [flagVote, setFlagVote] = useState(false);

  const [finished, setFinished] = useState<boolean>(false);

  const [userVoice, setUserVoice] = useState<boolean>(true);

  const [votes, setVotes] = useState<Array<Option>>([]);
  const [changeVotes, setChangeVotes] = useState<string[]>([]);
  const [userVoter, setUserVoter] = useState<boolean>(false);

  const [numberOfVotes, setNumberOfVotes] = useState<number>(0);

  const { userId, userName, isOwner } = getUserInfo(meetingId || '');

  const changeVote = (changeFlag: boolean) => {
    setUserVoice(changeFlag);
  };

  const changeFieldVote = async (data: Option) => {
    setChangeVotes([data.optionId]);
    voting([data.optionId]).then(() => {});
  };

  const voting = async (dataChangeVotes: Array<string> = []) => {
    let body = {
      pollId: pollId,
      optionIds: !!dataChangeVotes ? dataChangeVotes : changeVotes,
      userId: userId,
      voterName: userName,
    };

    await pollVoteAdd(meetingId, body);

    await fetchData();
  };

  const meetingSaveFinalDate = async (dataVotes?: Array<Option>) => {
    let dateTime = !!dataVotes ? dataVotes[0].dateTime : null;

    if (!!dateTime) {
      await meetingFinalDateUpdate({
        meetingId: meetingId,
        finalDate: dateTime,
      });
      await fetchData();
    } else if (!!selectedVotes) {
      await meetingFinalDateUpdate({
        meetingId: meetingId,
        finalDate: selectedVotes,
      });
      await fetchData();
    }
  };

  const voiceCompletion = async () => {
    let maxlength = 0;

    votes.forEach((el: Option) => {
      if (el.voters.length > 0) {
        maxlength = maxlength < el.voters.length ? el.voters.length : maxlength;
      }
    });

    let votesList: Array<Option> = votes.filter(
      (el: Option) => el.voters.length === maxlength
    );

    if (votesList.length > 1) {
      setModalSaveVote(true);
      setListVotes(votesList);
    } else {
      await meetingSaveFinalDate(votesList);
    }
  };

  const voiceDelete = async () => {
    await pollRemove(meetingId);
    navigate(`/${meetingId}`);
  };

  const fetchData = useCallback(async () => {
    try {
      if (meetingId) {
        const dataMeeting = await meetingGet(meetingId);

        setMeetingData(dataMeeting);

        setPollId(dataMeeting.pollId);

        const data = await pollGet(meetingId);

        data.options.forEach((el: Option) => {
          if (el.voters.length > 0) {
            el.voters.forEach((voter: Voter) => {
              if (voter.id === userId) setUserVoter(true);
            });
          }
        });

        setFinished(data.finished);
        setVotes(data.options);

        let voiceUser: Array<Option>;
        let counter = 0;
        voiceUser =
          data && data.options
            ? data.options.filter((el: Option) => {
                if (el.voters.length > 0) {
                  counter += el.voters.length;
                  return (
                    el.voters.filter((voter: Voter) => {
                      return voter.id === userId;
                    }).length > 0
                  );
                }
                return '';
              })
            : [];

        if (!!data) {
          let dataForSetVoteActive = data.options.filter(
            (el: Option) => el.voters.length > 0
          );
          if (dataForSetVoteActive.length > 0) {
            let index = 0;
            dataForSetVoteActive.forEach((el: Option, elIndex: number) => {
              index =
                el.voters.length > dataForSetVoteActive[index].voters.length
                  ? elIndex
                  : index;
            });
            setVoteActive(dataForSetVoteActive[index]);
          }
        }
        setFlagVote(!data.finished);

        setUserVoice(voiceUser.length > 0);
        setNumberOfVotes(counter);

        setFlagVote(!data.finished);
      }
    } catch (err) {}
  }, [meetingId, userId]);

  const onFinish = async (values: RegistrationEvent) => {
    const { name } = values;

    try {
      const userId = await meetingAttendeeCreate(
        meetingData?.id || '',
        name,
        false
      );

      setUserInfo(meetingData?.id || '', {
        userId,
        userName: name,
        isOwner: false,
      });

      await fetchData();
    } catch (err) {}
  };

  useEffect(() => {
    fetchData().then(() => {});
  }, [fetchData]);

  if (!userId) {
    return <GuestModal meetingData={meetingData} onDataCreated={onFinish} />;
  }

  return (
    <Page
      header={
        <>
          <Header
            page={'poll'}
            meetingData={meetingData}
            onDataCreated={fetchData}
          />
        </>
      }
    >
      <Modal
        title="Выбор даты голосования"
        centered={true}
        visible={modalSaveVote}
        confirmLoading={modalLoading}
        onOk={async () => {
          try {
            setModalLoading(true);
            await meetingSaveFinalDate();

            setModalSaveVote(false);
            setModalShow(false);
          } catch (err) {
            setModalLoading(false);
          }
        }}
        onCancel={() => {
          setModalSaveVote(false);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <Form
          layout="vertical"
          form={modalFormVotes}
          initialValues={{
            selectedVotes: selectedVotes,
          }}
        >
          <Select
            showSearch
            placeholder="Выберите финальную дату"
            onChange={(e) => {
              setSelectedVotes(e as unknown as Date);
            }}
          >
            {listVotes.map((el: Option) => {
              return (
                <Select.Option value={el.dateTime.toString()} key={el.optionId}>
                  {moment(el.dateTime).format('DD.MM.YYYY HH:mm')}
                </Select.Option>
              );
            })}
          </Select>
        </Form>
      </Modal>
      <Modal
        title="Выбор даты голосования самостоятельно"
        centered={true}
        visible={modalSaveVoteIndependently}
        confirmLoading={modalLoading}
        onOk={async () => {
          try {
            setModalLoading(true);

            await meetingFinalDateUpdate({
              meetingId: meetingId,
              finalDate: selectedDate.toISOString() as any,
            });

            await fetchData();

            setModalSaveVoteIndependently(false);
          } catch (err) {
            setModalSaveVoteIndependently(false);
          }
        }}
        onCancel={() => {
          setModalSaveVoteIndependently(false);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <DatePicker
          autoFocus
          format={'DD.MM.YYYY'}
          onSelect={(value) => {
            setSelectedDate(value);
          }}
        />
        <TimePicker
          style={{ marginLeft: '10px' }}
          defaultValue={selectedDate}
          format={'HH:mm'}
          onChange={(value) => {
            if (!!value) {
              setSelectedDate(
                selectedDate.hour(value.hour()).minute(value.minute())
              );
            }
          }}
        />
      </Modal>
      <Modal
        title="Редактировать мероприятие"
        centered={true}
        visible={modalShow}
        confirmLoading={modalLoading}
        onOk={async () => {
          try {
            setModalLoading(true);

            const { title, description } = await modalForm.validateFields();

            await meetingUpdate(meetingId, {
              id: meetingId,
              title: title || '',
              description: description || '',
            });

            await fetchData();

            setModalLoading(false);
            setModalShow(false);
          } catch (err) {
            setModalLoading(false);
          }
        }}
        onCancel={() => {
          modalForm.resetFields();

          setModalLoading(false);
          setModalShow(false);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <Form
          layout="vertical"
          form={modalForm}
          initialValues={{
            title: meetingData?.title,
            description: meetingData?.description,
          }}
        >
          <Form.Item
            name="title"
            label="Название мероприятия:"
            rules={[
              { required: true, message: 'Пожалуйста, введите Название' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание:">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
      <Steps className={styles.steps} current={2}>
        <Steps.Step
          key={0}
          title="Создание"
          subTitle=""
          description="Мероприятие зарегистрировано и доступно по ссылке для остальных участников"
        />
        <Steps.Step
          key={1}
          title="Выбор времени"
          subTitle=""
          description="Можно выбрать время, которое максимально удобно всем, либо добавить свое"
        />
        <Steps.Step
          key={2}
          title="Схождение"
          subTitle=""
          description="Создатель может запустить голосование или выбрать дату самостоятельно"
        />
        <Steps.Step
          key={3}
          title="Финал"
          subTitle=""
          description="Дата мероприятия утверджена создателем и является финальной"
        />
      </Steps>
      <Divider />
      {!!meetingData && (
        <Row gutter={{ xs: 0, sm: 0, md: 24, lg: 24 }}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Typography.Title level={5}>Голосование</Typography.Title>
            {!meetingData.pollId && 'Голосование еще не началось'}
            {!!votes &&
              votes
                .sort((a: Option, b: Option) =>
                  a.dateTime > b.dateTime ? 1 : -1
                )
                .map((el: Option) => (
                  <FieldVote
                    finished={finished}
                    active={changeVotes.includes(el.optionId)}
                    userVoice={userVoice}
                    counterVoice={numberOfVotes}
                    event={changeFieldVote}
                    data={el}
                    key={el.optionId}
                  />
                ))}
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {finished ? (
                <Typography.Title level={5}>
                  Голосование завершено
                </Typography.Title>
              ) : (
                <Typography.Title level={5}>
                  Управление голосованием
                </Typography.Title>
              )}
              {userVoice && !finished && !!votes && votes.length > 0 ? (
                <div>
                  <Button
                    style={{ width: '240px', marginBottom: '8px' }}
                    htmlType="button"
                    onClick={() => changeVote(false)}
                  >
                    Переголосовать
                  </Button>
                </div>
              ) : (
                ''
              )}
              {!userVoice && !finished ? (
                <Button
                  style={{ width: '240px', marginBottom: '8px' }}
                  htmlType="button"
                  className={'type-warning'}
                  type="primary"
                  disabled={!userVoter}
                  onClick={() => changeVote(true)}
                >
                  Отменить переголосование
                </Button>
              ) : (
                ''
              )}
              {votes && votes.length > 0 && isOwner && flagVote ? (
                <Button
                  style={{ width: '240px', marginBottom: '8px' }}
                  type="primary"
                  disabled={!(!!voteActive && voteActive.optionId)}
                  onClick={() => {
                    voiceCompletion().then();
                  }}
                >
                  Завершить
                </Button>
              ) : (
                ''
              )}
              {votes && votes.length > 0 && isOwner && flagVote ? (
                <Tooltip
                  placement="left"
                  title="Указать самостоятельно финальную дату"
                >
                  <Button
                    style={{ width: '240px', marginBottom: '8px' }}
                    type="primary"
                    disabled={!(!!voteActive && voteActive.optionId)}
                    onClick={() => {
                      setModalSaveVoteIndependently(true);
                    }}
                  >
                    Указать финальную дату
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
              {votes && votes.length > 0 && isOwner ? (
                <Button
                  style={{ width: '240px' }}
                  type={'primary'}
                  danger={true}
                  disabled={finished}
                  onClick={() => {
                    voiceDelete().then(() => {});
                  }}
                >
                  Удалить голосование
                </Button>
              ) : (
                ''
              )}
            </div>
          </Col>
        </Row>
      )}
    </Page>
  );
};

export default Poll;
