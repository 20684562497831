import randomColor from 'randomcolor';
import uniqid from 'uniqid';

import { instance } from './api';
import { AttendeesInTimeSlots } from './intersection';

export interface TimeSlot {
  dateTime: Date;
}

export interface Attendee {
  userId: string;
  name: string;
  owner?: boolean;
  timeSlots?: Date[];
  userColor?: string;
}

export interface Meeting {
  id?: string;
  title: string;
  description: string;
  ownerName?: string;
  attendees?: Attendee[];
}

export interface FinalDate {
  meetingId: string;
  finalDate: Date;
}

export interface MeetingView {
  id: string;
  pollId: string;
  title: string;
  description: string;
  ownerName: string;
  ownerId: string;
  attendees: Attendee[];
  attendeesInTimeSlots: AttendeesInTimeSlots;
  finalDate: Date;
  locked: boolean;
  invitedEmails: string[];
}

export interface RegistrationEvent {
  name: string;
  title: string;
  description: string;
}

const meetingUpdate = async (meetingId: string, payload: Meeting) => {
  const { data } = await instance.put(`/meeting/${meetingId}`, payload);

  return data;
};

const meetingCreate = async (
  name: string,
  title: string,
  description: string
): Promise<{
  meetingId: string;
  userId: string;
}> => {
  let body = {
    title: title || '',
    description: description || '',
    ownerName: name,
    attendees: [],
  };

  const { data: meetingId } = await instance.post('/meeting/', body);

  const userId = await meetingAttendeeCreate(meetingId, name, true);

  return {
    meetingId,
    userId,
  };
};

const meetingAttendeeUpdate = async (meetingId: string, payload: Attendee) => {
  const { data } = await instance.put(
    `/meeting/${meetingId}/attendee`,
    payload
  );

  return data;
};

const meetingAttendeeCreate = async (
  meetingId: string,
  name: string,
  owner: boolean = false
) => {
  const userId = uniqid();
  const userColor = randomColor();

  await instance.post(`/meeting/${meetingId}/attendee`, {
    userId,
    userColor,
    name,
    owner,
    timeSlots: [],
  });

  return userId;
};

const meetingFinalDateUpdate = async (payload: FinalDate) => {
  const { data } = await instance.put(`/meeting/${payload.meetingId}/date`, {
    date: payload.finalDate,
  });

  return data;
};

const meetingTimeSlotCreate = async (
  meetingId: string,
  attendeeId: string,
  payload: TimeSlot
) => {
  const { data } = await instance.post(
    `/meeting/${meetingId}/attendee/${attendeeId}/date`,
    {
      date: payload.dateTime,
    }
  );

  return data;
};

const meetingTimeSlotRemove = async (
  meetingId: string,
  attendeeId: string,
  date: Date
) => {
  const { data } = await instance.delete(
    `/meeting/${meetingId}/attendee/${attendeeId}/date`,
    {
      params: {
        date,
      },
    }
  );

  return data;
};

const meetingGet = async (id: string): Promise<MeetingView> => {
  const { data } = await instance.get(`/meeting/${id}`);

  return data;
};

const meetingRemove = async (id: string) => {
  const { data } = await instance.delete(`/meeting/${id}`);

  return data;
};

const meetingAttendeeRemove = async (meetingId: string, id: string) => {
  const { data } = await instance.delete(
    `/meeting/${meetingId}/attendee/${id}`
  );

  return data;
};

export {
  meetingUpdate,
  meetingCreate,
  meetingAttendeeUpdate,
  meetingAttendeeCreate,
  meetingFinalDateUpdate,
  meetingTimeSlotCreate,
  meetingTimeSlotRemove,
  meetingGet,
  meetingRemove,
  meetingAttendeeRemove,
};
