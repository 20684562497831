import React from 'react';

interface UserInfo {
  userId: string;
  userName: string;
  isOwner: boolean;
}

interface User {
  getUserInfo: (meetingId: string) => UserInfo;
  setUserInfo: (meetingId: string, userInfo: UserInfo) => void;
}

const userContextDefaultValue: User = {
  getUserInfo: (meetingId) => {
    try {
      return JSON.parse(window.localStorage.getItem(meetingId) || '');
    } catch (e) {
      return {
        userId: '',
        userName: '',
        isOwner: false,
      };
    }
  },
  setUserInfo: (meetingId, userInfo) => {
    window.localStorage.setItem(meetingId, JSON.stringify(userInfo));
  },
};

export { userContextDefaultValue };

export default React.createContext<User>(userContextDefaultValue);
