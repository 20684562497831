import { Card } from 'antd';
import React, { ReactNode } from 'react';

import styles from './Window.module.sass';

interface IProps {
  header: ReactNode;
}

const Window: React.FC<IProps> = (props) => {
  const { children, header } = props;

  return (
    <div className={styles.window}>
      <Card title={header} bordered={false} className={styles.box}>
        {children}
      </Card>
    </div>
  );
};

export default Window;
