import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Divider,
  Grid,
  List,
  Row,
  Steps,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Calendar from '../../components/Calendar';
import GuestModal from '../../components/GuestModal';
import Header from '../../components/Header';
import Page from '../../components/Page';
import styles from '../../components/Page/Page.module.sass';
import userContext from '../../contexts/userContext';
import {
  MeetingView,
  RegistrationEvent,
  meetingAttendeeCreate,
  meetingGet,
  meetingTimeSlotCreate,
  meetingTimeSlotRemove,
} from '../../services/meeting';
import Error from '../Error';

window.moment = moment;

const Meeting: React.FC = () => {
  const [meetingData, setMeetingdata] = useState<MeetingView>();
  const [meetingDataLoaded, setMeetingDataLoaded] = useState<boolean>(false);

  const { xs } = Grid.useBreakpoint();

  const { meetingId } = useParams();
  const { getUserInfo, setUserInfo } = useContext(userContext);

  const { userId } = getUserInfo(meetingId || '');

  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    try {
      if (meetingId) {
        const data = await meetingGet(meetingId);

        setMeetingdata(data);

        setMeetingDataLoaded(true);
      }
    } catch (err) {
      setMeetingDataLoaded(true);
    }
  }, [meetingId]);

  useEffect(() => {
    fetchData().then(() => {});
  }, [fetchData]);

  if (!meetingData) {
    return meetingDataLoaded ? (
      <Error />
    ) : (
      <div className="centered">
        <LoadingOutlined style={{ fontSize: '50px' }} />
      </div>
    );
  }

  const onFinish = async (values: RegistrationEvent) => {
    const { name } = values;

    try {
      setLoading(true);

      const userId = await meetingAttendeeCreate(meetingData.id, name, false);

      setUserInfo(meetingData.id, {
        userId,
        userName: name,
        isOwner: false,
      });

      setLoading(false);

      await fetchData();
    } catch (err) {
      setLoading(false);
    }
  };

  if (!userId) {
    return <GuestModal meetingData={meetingData} onDataCreated={onFinish} />;
  }

  return (
    <Page
      header={
        <Header
          page={'meeting'}
          meetingData={meetingData}
          onDataCreated={fetchData}
        />
      }
    >
      <Steps className={styles.steps} current={!!meetingData.pollId ? 2 : 1}>
        <Steps.Step
          key={0}
          title="Создание"
          subTitle=""
          description="Мероприятие зарегистрировано и доступно по ссылке для остальных участников"
        />
        <Steps.Step
          key={1}
          title="Выбор времени"
          subTitle=""
          description="Можно выбрать время, которое максимально удобно всем, либо добавить свое"
        />
        <Steps.Step
          key={2}
          title="Схождение"
          subTitle=""
          description="Создатель может запустить голосование или выбрать дату самостоятельно"
        />
        <Steps.Step
          key={3}
          title="Финал"
          subTitle=""
          description="Дата мероприятия утверджена создателем и является финальной"
        />
      </Steps>
      <Divider />
      {/* xs  sm    md    lg    xl     xxl */}
      {/* 0px 576px 768px 992px 1200px 1600px */}
      <Row gutter={{ xs: 0, sm: 0, md: 24, lg: 24 }}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
          <Typography.Title level={4}>Список пользователей:</Typography.Title>
          <Divider />
          <List
            itemLayout="horizontal"
            dataSource={meetingData.attendees}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <div>
                      <Badge
                        color={item.userColor}
                        text={
                          <>
                            <b>{item.name}</b>
                            {item.userId === userId && (
                              <>
                                {' '}
                                <i>(Это Вы)</i>
                              </>
                            )}
                            {item.owner && (
                              <>
                                {' '}
                                <i>(Владелец)</i>
                              </>
                            )}
                          </>
                        }
                      />
                    </div>
                  }
                  description={
                    <div>
                      {(item.timeSlots || []).map((dateTime, k) => {
                        return (
                          <div>
                            <Tag
                              key={k}
                              style={{
                                fontSize: '1.1em',
                                padding: '5px 10px',
                                margin: '0 5px 5px 0',
                              }}
                            >
                              {moment(dateTime).format('DD.MM.YYYY HH:mm')}
                              {item.userId === userId && (
                                <span>
                                  <Divider type="vertical" />
                                  <CloseOutlined
                                    className="ant-tag-close-icon"
                                    style={{
                                      position: 'relative',
                                      top: '-1px',
                                    }}
                                    onClick={async (event) => {
                                      try {
                                        await meetingTimeSlotRemove(
                                          meetingData.id,
                                          item.userId,
                                          dateTime
                                        );

                                        await fetchData();
                                      } catch (e) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </span>
                              )}
                            </Tag>
                          </div>
                        );
                      })}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          <Divider />
          <Typography.Title level={4}>Топ выбранных дат:</Typography.Title>
          <Divider />
          <List
            itemLayout="horizontal"
            dataSource={Object.keys(
              meetingData.attendeesInTimeSlots || {}
            ).sort((a, b) => {
              const lengthA = meetingData!.attendeesInTimeSlots![a].length;
              const lengthB = meetingData!.attendeesInTimeSlots![b].length;

              if (lengthA === lengthB) {
                return moment(a).valueOf() - moment(b).valueOf();
              }

              return lengthB - lengthA;
            })}
            renderItem={(item: string) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <div
                      style={{
                        color: 'rgba(0,0,0,.85)',
                        fontSize: '14px',
                        fontWeight: 'bold',
                      }}
                    >
                      {moment(item).format('DD.MM.YYYY HH:mm')}
                    </div>
                  }
                  description={
                    <div>
                      {(
                        (meetingData.attendeesInTimeSlots &&
                          meetingData.attendeesInTimeSlots[item]) ||
                        []
                      ).map((it, k) => {
                        return (
                          <Tag
                            key={k}
                            style={{
                              fontSize: '1.1em',
                              padding: '5px 10px',
                              margin: '0 5px 5px 0',
                            }}
                          >
                            <Badge
                              color={
                                meetingData?.attendees.find(
                                  (user) => user.userId === it
                                )?.userColor
                              }
                              text={
                                meetingData?.attendees.find(
                                  (user) => user.userId === it
                                )?.name
                              }
                            />
                            {it === userId && (
                              <span>
                                <Divider type="vertical" />
                                <CloseOutlined
                                  className="ant-tag-close-icon"
                                  style={{ position: 'relative', top: '-1px' }}
                                  onClick={async (event) => {
                                    try {
                                      await meetingTimeSlotRemove(
                                        meetingData.id,
                                        it,
                                        item as any
                                      );

                                      await fetchData();
                                    } catch (e) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </span>
                            )}
                          </Tag>
                        );
                      })}
                      {!meetingData.attendeesInTimeSlots![item]?.includes(
                        userId
                      ) && (
                        <Button
                          type="dashed"
                          loading={loading}
                          onClick={async () => {
                            try {
                              setLoading(true);

                              await meetingTimeSlotCreate(
                                meetingData.id,
                                userId,
                                {
                                  dateTime: moment(item).toISOString() as any,
                                }
                              );

                              await fetchData();

                              setLoading(false);
                            } catch (err) {
                              setLoading(false);
                            }
                          }}
                        >
                          <PlusOutlined /> Добавить себя
                        </Button>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
          {xs && <Divider />}
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
          <Calendar meetingData={meetingData} onDateCreated={fetchData} />
        </Col>
      </Row>
    </Page>
  );
};

export default Meeting;
