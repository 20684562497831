import 'antd/dist/antd.min.css';

import './styles/main.sass';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import UserContext, { userContextDefaultValue } from './contexts/userContext';
import Error from './pages/Error';
import Final from './pages/Final';
import Home from './pages/Home';
import Meeting from './pages/Meeting';
import Poll from './pages/poll';

const App: React.FC = () => {
  return (
    <UserContext.Provider value={userContextDefaultValue}>
      <ConfigProvider locale={ruRU}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:meetingId" element={<Meeting />} />
            <Route path="/:meetingId/poll" element={<Poll />} />
            <Route path="/:meetingId/final" element={<Final />} />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </UserContext.Provider>
  );
};

export default App;
