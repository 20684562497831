import { MenuOutlined } from '@ant-design/icons';
import { Button, Grid, Layout } from 'antd';
import React, { ReactNode, useState } from 'react';

import packageInfo from '../../../package.json';
import { ReactComponent as Logo } from './logo.svg';
import styles from './Page.module.sass';

interface IProps {
  header: ReactNode;
}

const Page: React.FC<IProps> = (props) => {
  const { children, header } = props;

  const { xs } = Grid.useBreakpoint();

  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Layout className={styles.page}>
      <Layout.Header className={styles.header}>
        {xs && (
          <>
            <div
              style={
                xs
                  ? {
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }
                  : {}
              }
            >
              <Button ghost={true} onClick={() => setVisible(!visible)}>
                <MenuOutlined />
              </Button>
              <span style={{ fontSize: '20px' }}>Мои Кореша</span>
              <a href="/" style={{ display: 'flex' }}>
                <Logo />
              </a>
            </div>
            {visible && header}
          </>
        )}
        <div className="space-between">
          {!xs && (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '20px',
                }}
              >
                <a href="/" style={{ display: 'flex', marginRight: '24px' }}>
                  <Logo />
                </a>
                <span>Мои Кореша</span>
              </div>
              {header}
            </>
          )}
        </div>
      </Layout.Header>
      <Layout.Content className={styles.content}>{children}</Layout.Content>
      <Layout.Footer className={styles.footer}>
        {new Date().getFullYear()}
        &nbsp;&copy;&nbsp;Мои&nbsp;кореша&nbsp;(v{packageInfo.version})
      </Layout.Footer>
    </Layout>
  );
};

export default Page;
