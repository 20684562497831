import axios from 'axios';
let token =
  'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiaWxsQG1pY3Jvc29mdC5jb20iLCJhdXRob3JpdGllcyI6W3siYXV0aG9yaXR5IjoiUk9MRV9PV05FUiJ9XSwiaWF0IjoxNjQzMDE1NjU3LCJleHAiOjE2NDMwMTkyNTd9.zuhx0ieY2LvDv_tPshNVok0md2gX_e6MUH6FHM-Bf_s';
const instance = axios.create({
  // baseURL: 'http://192.168.234.152:9001',
  // baseURL: 'http://192.168.234.102:9001',
  headers: { Authorization: 'Bearer ' + token },
  baseURL: '/api',
});

export { instance };
