import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Input,
  Menu,
  Modal,
  Tooltip,
  Typography,
} from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
import moment from 'moment';
import React, {
  HTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import userContext from '../../contexts/userContext';
import style from '../../pages/Meeting/Meeting.module.sass';
import { Attendee, MeetingView, meetingUpdate } from '../../services/meeting';
import { pollCreate } from '../../services/poll';
import styleHeader from './Header.module.sass';

interface IProps extends HTMLAttributes<string> {
  page: string;
  meetingData?: MeetingView;
  onDataCreated?: () => Promise<void>;
}

const Header: React.FC<IProps> = (props) => {
  const { meetingData, page, onDataCreated } = props;

  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalForm] = Form.useForm();

  const { xs } = Grid.useBreakpoint();

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState<boolean>(false);

  const [pollId, setPollId] = useState('');

  const { getUserInfo } = useContext(userContext);
  const { meetingId } = useParams();

  const { userName, isOwner } = getUserInfo(meetingId || '');

  const fetchData = useCallback(async () => {
    try {
      if (meetingId) {
        setPollId(meetingData?.pollId || '');
      }
    } catch (err) {}
  }, [meetingId, meetingData?.pollId]);

  const menu = (
    <Menu>
      {(page.includes('meeting') || page.includes('final')) &&
        meetingData?.id &&
        !!pollId &&
        pollId.length > 0 && (
          <Menu.Item>
            <a href={`/${meetingId}/poll`}>Голосование</a>
          </Menu.Item>
        )}
      {(page.includes('poll') || page.includes('final')) && (
        <Menu.Item>
          <a href={`/${meetingId}`}>Календарь</a>
        </Menu.Item>
      )}
      {!!meetingData?.finalDate && !page.includes('final') && (
        <Menu.Item>
          <a href={`/${meetingId}/final`}>Финал</a>
        </Menu.Item>
      )}
    </Menu>
  );

  useEffect(() => {
    fetchData().then(() => {});
  }, [fetchData]);

  const CreatePoll = () => {
    pollCreate(meetingId || '').then(({ id }) => {
      setPollId(id);
      GoPoll();
    });
  };

  const GoPoll = () => {
    navigate(`/${meetingId}/poll`);
  };

  return (
    <>
      <Divider
        type={xs ? 'horizontal' : 'vertical'}
        style={
          !xs
            ? {
                borderColor: 'rgba(255, 255, 255, 0.3)',
                margin: '0px 24px',
                height: '64px',
              }
            : {
                borderColor: 'rgba(255, 255, 255, 0.3)',
                margin: 0,
              }
        }
      />
      <div className={!xs ? 'space-between' : ''}>
        <div>
          <div
            style={{ display: xs ? 'block' : 'flex' }}
            className={styleHeader.Block}
          >
            {!!meetingData?.title && meetingData.title.length > 10 ? (
              <Tooltip placement="bottom" title={meetingData?.title}>
                <b>{meetingData?.title}</b>
              </Tooltip>
            ) : (
              <b>{meetingData?.title}</b>
            )}
            {meetingData?.description && (
              <>
                <i>&nbsp;&mdash;&nbsp;</i>
                {meetingData?.description.length > 10 ? (
                  <Tooltip placement="bottom" title={meetingData?.description}>
                    <i>{meetingData?.description}</i>
                  </Tooltip>
                ) : (
                  <i>{meetingData?.description}</i>
                )}
              </>
            )}

            {isOwner && (
              <Button
                type="link"
                danger={true}
                onClick={() => {
                  setModalShow(true);
                }}
              >
                <EditOutlined />
              </Button>
            )}
          </div>
        </div>
        {xs && (
          <Divider
            type={xs ? 'horizontal' : 'vertical'}
            style={{
              borderColor: 'rgba(255, 255, 255, 0.3)',
              margin: xs ? '0' : '0 8px',
            }}
          />
        )}
        <div>
          {!!meetingData?.finalDate && (
            <span>
              Финальная дата:{' '}
              {moment(meetingData?.finalDate).format('DD.MM.YYYY HH:mm')}
            </span>
          )}
          {!!meetingData?.finalDate && (
            <Divider
              type={xs ? 'horizontal' : 'vertical'}
              style={{
                borderColor: 'rgba(255, 255, 255, 0.3)',
                margin: xs ? '0' : '0 8px',
              }}
            />
          )}

          <Typography.Paragraph
            copyable={{
              text: `${window.location.protocol}//${window.location.host}/${meetingId}`,
              tooltips: ['Скопировать ссылку на мероприятие'],
            }}
            style={{
              display: xs ? 'block' : 'inline-block',
              marginBottom: 0,
              color: '#fff',
            }}
          >
            {meetingId}
          </Typography.Paragraph>
          <Divider
            type={xs ? 'horizontal' : 'vertical'}
            style={{
              borderColor: 'rgba(255, 255, 255, 0.3)',
              margin: xs ? '0' : '0 8px',
            }}
          />
          <div
            style={
              !xs
                ? {
                    display: 'inline-block',
                  }
                : {}
            }
            className={xs ? 'space-between' : ''}
          >
            <div
              style={{
                display: xs ? 'block' : 'inline-block',
              }}
            >
              <span>{userName}</span>
              {isOwner && (
                <span style={{ color: '#ff4d4f' }}> (Вы владелец)</span>
              )}
            </div>
            {!xs && (
              <Divider
                type={xs ? 'vertical' : 'vertical'}
                style={{ borderColor: 'rgba(255, 255, 255, 0.3)' }}
              />
            )}
            {page.includes('meeting') && isOwner && !pollId && meetingData?.id && (
              <Button
                className={style.btn}
                type={'dashed'}
                ghost={true}
                disabled={
                  meetingData?.attendees?.filter((el: Attendee) =>
                    el.timeSlots ? el.timeSlots.length > 0 : false
                  ).length === 0
                }
                onClick={() => {
                  CreatePoll();
                }}
              >
                Создать голосование
              </Button>
            )}
            {page.includes('meeting') && isOwner && !pollId && meetingData?.id && (
              <Divider
                type={xs ? 'horizontal' : 'vertical'}
                style={{
                  borderColor: 'rgba(255, 255, 255, 0.3)',
                  margin: xs ? '0' : '0 8px',
                }}
              />
            )}
            <div style={{ display: 'inline-block' }}>
              <Button type="dashed" ghost={true}>
                Вход по e-mail
              </Button>
            </div>
            <Divider
              type={xs ? 'horizontal' : 'vertical'}
              style={{
                borderColor: 'rgba(255, 255, 255, 0.3)',
                margin: xs ? '0' : '0 8px',
              }}
            />
            <Dropdown
              disabled={
                menu.props.children.filter((el: MenuItem) => !!el).length === 0
              }
              className={style.btn}
              overlay={menu}
              placement="bottomLeft"
            >
              <Button type="dashed" ghost={true}>
                Навигация
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
      <Modal
        title="Редактировать мероприятие"
        centered={true}
        visible={modalShow}
        confirmLoading={modalLoading}
        onOk={async () => {
          try {
            setModalLoading(true);

            const { title, description } = await modalForm.validateFields();

            await meetingUpdate(meetingData?.id || '', {
              id: meetingData?.id,
              title: title || '',
              description: description || '',
            });

            setModalLoading(false);
            setModalShow(false);

            if (onDataCreated) {
              await onDataCreated();
            }
          } catch (err) {
            setModalLoading(false);
          }
        }}
        onCancel={() => {
          modalForm.resetFields();

          setModalLoading(false);
          setModalShow(false);
        }}
        okText="Сохранить"
        cancelText="Отмена"
      >
        <Form
          layout="vertical"
          form={modalForm}
          initialValues={{
            title: meetingData?.title,
            description: meetingData?.description,
          }}
        >
          <Form.Item
            name="title"
            label="Название мероприятия:"
            rules={[
              { required: true, message: 'Пожалуйста, введите Название' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Описание:">
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Header;
