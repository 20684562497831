import { instance } from './api';

export interface Vote {
  pollId: string;
  optionIds: string[];
  userId: string;
  voterName: string;
}

export interface Voter {
  id: string;
  name: string;
}

export interface Option {
  optionId: string;
  dateTime: Date;
  voters: Voter[];
}

export interface Poll {
  id: string;
  meetingId: string;
  options: Option[];
  finished: boolean;
}

const pollVoteAdd = async (meetingId: string, payload: Vote) => {
  const { data } = await instance.put(`/poll/${meetingId}/vote`, payload);
  return data;
};

const pollCreate = async (meetingId: string): Promise<Poll> => {
  const { data } = await instance.post(`/poll/${meetingId}`);

  return data;
};

const pollGet = async (meetingId: string): Promise<Poll> => {
  const { data } = await instance.get(`/poll/${meetingId}`);

  return data;
};

const pollRemove = async (meetingId: string) => {
  const { data } = await instance.delete(`/poll/${meetingId}`);

  return data;
};

export { pollVoteAdd, pollCreate, pollGet, pollRemove };
