import { Button, Form, Input, Tooltip } from 'antd';
import React, { HTMLAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MeetingView, RegistrationEvent } from '../../services/meeting';
import Window from '../Window';

interface IProps extends HTMLAttributes<string> {
  meetingData?: MeetingView;
  onDataCreated?: (values: RegistrationEvent) => Promise<void>;
}

const GuestModal: React.FC<IProps> = (props) => {
  const { meetingData, onDataCreated } = props;

  const [form] = Form.useForm();

  const [loading] = useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <>
      <Window
        header={
          meetingData?.title && meetingData?.title.length > 0
            ? meetingData?.title.substr(0, 49) + '...'
            : meetingData?.title
        }
      >
        <div style={{ marginBottom: '24px' }}>
          {meetingData?.ownerName} уже&nbsp;ждет Вас и&nbsp;предлагает
          <br />
          присоединиться к&nbsp;мероприятию!
        </div>
        <Form layout="vertical" form={form} onFinish={onDataCreated}>
          <Form.Item
            name="name"
            label="Как Вас зовут?"
            rules={[{ required: true }]}
          >
            <Input placeholder="Почетный гость" />
          </Form.Item>
          <div className="space-between">
            <Tooltip
              placement="left"
              title="Перейти на страницу создания мероприятия"
            >
              <Button
                style={{ paddingLeft: 0 }}
                htmlType="button"
                type="link"
                onClick={() => navigate(`/`)}
              >
                Может хотите создать свое?
              </Button>
            </Tooltip>
            <Button type="primary" htmlType="submit" loading={loading}>
              Войти
            </Button>
          </div>
        </Form>
      </Window>
    </>
  );
};

export default GuestModal;
