import 'moment/locale/ru';

import { Alert, Calendar as AntCalendar, Button, Grid, TimePicker } from 'antd';
import ruRU from 'antd/lib/calendar/locale/ru_RU';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';

import userContext from '../../contexts/userContext';
import {
  Attendee,
  MeetingView,
  meetingTimeSlotCreate,
} from '../../services/meeting';
import CalendarStyle from './Calendar.module.sass';

moment.updateLocale('ru', {
  weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  monthsShort:
    'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь'.split(
      '_'
    ),
});

interface IProps {
  meetingData: MeetingView;
  onDateCreated?: () => Promise<void>;
}

const Calendar: React.FC<IProps> = (props) => {
  const { meetingData: meetingDataProp, onDateCreated } = props;

  const [meetingData, setMeetingData] = useState<MeetingView>(meetingDataProp);

  const { xs } = Grid.useBreakpoint();

  useEffect(() => {
    setMeetingData(meetingDataProp);
  }, [meetingDataProp]);

  const [selectedDate, setSelectedDate] = useState<Moment>(moment());

  const [loading, setLoading] = useState<boolean>(false);

  const { getUserInfo } = useContext(userContext);

  const { userId } = getUserInfo(meetingData.id);

  const dateCellRender = (date: Moment) => {
    interface timeSlotsI {
      userName: string;
      userColor?: string;
    }

    let timeSLots: {
      [key: string]: {
        users: [timeSlotsI];
      };
    } = {};

    for (let i in meetingData.attendeesInTimeSlots) {
      meetingData.attendeesInTimeSlots[i].forEach((elTimeSLot: string) => {
        meetingData.attendees.forEach((elAttendees: Attendee) => {
          if (
            elAttendees.userId === elTimeSLot &&
            date.isSame(i.toString(), 'day')
          ) {
            if (timeSLots[i]?.users?.length > 0) {
              timeSLots[i].users.push({
                userName: elAttendees.name,
                userColor: elAttendees.userColor,
              });
            } else {
              timeSLots[i] = {
                users: [
                  {
                    userName: elAttendees.name,
                    userColor: elAttendees.userColor,
                  },
                ],
              };
            }
          }
        });
      });
    }

    return (
      <div className="fit">
        <ul style={{ listStyle: 'none', margin: 0, padding: 0 }}>
          {Object.keys(timeSLots).map((userId) => (
            <li key={userId}>
              <div className={CalendarStyle.blockTime}>
                <div className={CalendarStyle.time}>
                  {moment(userId).format('DD.MM.YYYY HH:mm')}
                </div>
                <div>
                  {timeSLots[userId].users.map((timeUser: timeSlotsI, k) => {
                    return (
                      <div className={CalendarStyle.badge} key={k}>
                        <div
                          className={CalendarStyle.badgeCircle}
                          style={{ background: timeUser.userColor }}
                        />
                        <div>{!xs && timeUser.userName}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Alert
        message={
          <div className="space-between">
            <div>
              <span>Выбранная дата:</span>{' '}
              <b>{selectedDate && selectedDate.format('DD.MM.YYYY')}</b>
            </div>
            <div>
              <div>
                <TimePicker
                  style={{ marginRight: '10px' }}
                  defaultValue={selectedDate}
                  format={'HH:mm'}
                  onChange={(value) => {
                    if (!!value) {
                      setSelectedDate(
                        selectedDate.hour(value.hour()).minute(value.minute())
                      );
                    }
                  }}
                />
                <Button
                  size="small"
                  type="primary"
                  loading={loading}
                  onClick={async () => {
                    try {
                      setLoading(true);
                      await meetingTimeSlotCreate(meetingData.id, userId, {
                        dateTime: selectedDate.toISOString() as any,
                      });

                      if (onDateCreated) {
                        await onDateCreated();
                      }

                      setLoading(false);
                    } catch (err) {
                      setLoading(false);
                    }
                  }}
                >
                  Добавить
                </Button>
              </div>
            </div>
          </div>
        }
        style={{
          marginBottom: '5px',
        }}
      />
      <AntCalendar
        style={{ padding: '0 12px', border: '1px solid rgba(0, 0, 0, 0.06)' }}
        locale={ruRU}
        dateCellRender={dateCellRender}
        onSelect={(value) => setSelectedDate(value)}
      />
    </div>
  );
};

export default Calendar;
