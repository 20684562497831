import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import GuestModal from '../../components/GuestModal';
import Header from '../../components/Header/Header';
import Page from '../../components/Page';
import userContext from '../../contexts/userContext';
import {
  Attendee,
  MeetingView,
  RegistrationEvent,
  meetingAttendeeCreate,
  meetingGet,
} from '../../services/meeting';
import Error from '../Error';
import StyleFinal from './Final.module.sass';

const Final: React.FC = () => {
  const { meetingId } = useParams();
  const { getUserInfo, setUserInfo } = useContext(userContext);

  const [meetingData, setMeetingData] = useState<MeetingView>();

  const [meetingDataLoaded, setMeetingDataLoaded] = useState<boolean>(false);

  const { userId } = getUserInfo(meetingId || '');

  const fetchData = useCallback(async () => {
    try {
      if (meetingId) {
        const data = await meetingGet(meetingId);

        setMeetingData(data);

        setMeetingDataLoaded(true);
      }
    } catch (err) {
      setMeetingDataLoaded(true);
    }
  }, [meetingId]);

  useEffect(() => {
    fetchData().then();
  }, [fetchData]);

  if (!meetingData) {
    return meetingDataLoaded ? (
      <Error />
    ) : (
      <div className="centered">
        <LoadingOutlined style={{ fontSize: '50px' }} />
      </div>
    );
  }

  const onFinish = async (values: RegistrationEvent) => {
    const { name } = values;

    try {
      const userId = await meetingAttendeeCreate(
        meetingData?.id || '',
        name,
        false
      );

      setUserInfo(meetingData?.id || '', {
        userId,
        userName: name,
        isOwner: false,
      });

      await fetchData();
    } catch (err) {}
  };

  if (!userId) {
    return <GuestModal meetingData={meetingData} onDataCreated={onFinish} />;
  }

  return (
    <Page
      header={
        <Header
          page={'final'}
          meetingData={meetingData}
          onDataCreated={fetchData}
        />
      }
    >
      <div className={StyleFinal.FinalBlock}>
        <div>
          <span>Название: </span>
          {meetingData.title}
        </div>
        {!!meetingData.description && (
          <div>
            <span>Описание: </span>
            {meetingData.description}
          </div>
        )}
        <div>
          <span>Финальная дата: </span>
          {moment(meetingData?.finalDate).format('DD.MM.YYYY HH:mm')}
        </div>
        <div>
          <span>Участники:</span>
          {meetingData.attendees.map(
            (el: Attendee, index: number) =>
              ` ${el.name}${
                index !== meetingData.attendees.length - 1 ? ',' : ''
              } `
          )}
        </div>
      </div>
    </Page>
  );
};

export default Final;
